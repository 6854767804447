.strip {
    background-image: url("../assests/Images/imgHome/strip.png");
    background-size: cover;
    background-position: center center;
    position: relative;
    bottom: 20px;
    height: 550px;
    display: flex;
    padding: 200px;
    direction: rtl;
}

.logo {
    background-image: url("../assests/Images/imgHome/logo.png");
    background-size: contain;
    background-position: center center;
    height: 80px;
    background-repeat: no-repeat;
}

.stripTxt {
    color: white ! important;
    position: absolute;
    right: 100px;
}

.boxInStrip {
    direction: rtl;
    background: white;
    height: 150px;
    display: flex;
    align-items: center;
    border-radius: 15px;
    margin-bottom: 100px;
    margin-left: 50px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .08), 0 4px 12px rgba(0, 0, 0, .08);
}

.categoryName {
    font-size: 1.7em;
    font-weight: 500;
    padding-bottom: 20px;
}

.courseOfTheWeek {
    border: solid rgb(203, 203, 203) 1px;
}

.courseOfTheWeek:hover {
    background: rgba(128, 128, 128, 0.075);
}

.courseOfTheWeek div:first-child {
    width: 50%;
    height: 350px;
}

.courseOfTheWeek div:last-child {
    width: 50%;
    height: 350px;
}


/* swiper */

.swiperSlideX {
    height: 300px;
}

.input {}

.link {
    padding-bottom: 50px;
}

.link a {
    text-decoration: none;
    font-size: 2em;
    color: blueviolet;
    font-weight: bold;
    transition: 800ms;
}

.link a:hover {
    color: rgb(167, 31, 172);
    text-decoration: underline;
}


/*
#  $$\      $$\                 $$\ $$\                  $$$$$$\                                $$\
#  $$$\    $$$ |                $$ |\__|                $$  __$$\                               \__|
#  $$$$\  $$$$ | $$$$$$\   $$$$$$$ |$$\  $$$$$$\        $$ /  $$ |$$\   $$\  $$$$$$\   $$$$$$\  $$\  $$$$$$\   $$$$$$$\
#  $$\$$\$$ $$ |$$  __$$\ $$  __$$ |$$ | \____$$\       $$ |  $$ |$$ |  $$ |$$  __$$\ $$  __$$\ $$ |$$  __$$\ $$  _____|
#  $$ \$$$  $$ |$$$$$$$$ |$$ /  $$ |$$ | $$$$$$$ |      $$ |  $$ |$$ |  $$ |$$$$$$$$ |$$ |  \__|$$ |$$$$$$$$ |\$$$$$$\
#  $$ |\$  /$$ |$$   ____|$$ |  $$ |$$ |$$  __$$ |      $$ $$\$$ |$$ |  $$ |$$   ____|$$ |      $$ |$$   ____| \____$$\
#  $$ | \_/ $$ |\$$$$$$$\ \$$$$$$$ |$$ |\$$$$$$$ |      \$$$$$$ / \$$$$$$  |\$$$$$$$\ $$ |      $$ |\$$$$$$$\ $$$$$$$  |
#  \__|     \__| \_______| \_______|\__| \_______|       \___$$$\  \______/  \_______|\__|      \__| \_______|\_______/
#                                                            \___|
*/

@media screen and (max-width:900px) {
    .strip {
        height: 350px;
        bottom: 32px;
    }
    .courseOfTheWeek div:first-child {
        width: 100% !important;
        height: auto;
        order: 1;
        align-items: center;
    }
    .courseOfTheWeek div:last-child {
        width: 100% !important;
        height: 320px;
    }
    .paddingCourseOfTheWeek {
        padding: 30px;
    }
    .btnWeek {
        margin: 0 auto !important;
    }
}

@media screen and (max-width:600px) {
    .boxInStrip {
        display: none !important;
    }
    .strip {
        height: 250px;
        bottom: 32px;
    }
    .strip h1 {
        margin-right: 30px;
    }
    .courseOfWeekH {
        text-align: center !important;
    }
    .categoryName {
        text-align: center;
        padding-bottom: 20px;
        font-weight: bold;
    }
    .logo {
        background-image: url("../assests/Images/imgHome/logo.png");
        background-size: contain;
        background-position: center center;
        height: 100px;
        background-repeat: no-repeat;
    }
    .stripTxt {
        color: white ! important;
        position: absolute;
        right: 0;
        top: 100px;
        width: 55%;
    }
}