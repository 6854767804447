.myShadow {
    box-shadow: inset 0px 2px 2px 2px #0000001a;
    border: none;
}

.formCtrl {
    display: block;
    height: 40px;
    border: #0000001a 1px;
    border-style: none dashed dashed dashed;
    border-radius: 6px;
    padding: 0 10px 0 0;
    width: 100%;
}

.formCtrl:focus {
    outline: none;
    background: #21212113;
    transition: 1s;
}

.formPassword {
    background: none;
    border: none;
}

.formPassword:focus {
    outline: none;
    background: none;
    transition: 1s;
}