.btn {
    border: none;
    background: #212121 !important;
    color: white;
    width: 200px;
}

.myShadow {
    box-shadow: inset 0px 2px 2px 2px #0000001a;
    border: none;
}

.formCtrl {
    display: block;
    height: 40px;
    border: #0000001a 1px;
    border-style: none dashed dashed dashed;
    border-radius: 6px;
    padding: 0 10px 0 0;
    width: 100%;
}

.formCtrl:focus {
    outline: none;
    background: #21212113;
    transition: 1s;
}

.textArea {
    display: block;
    border: #0000001a 1px;
    border-style: none dashed dashed dashed;
    border-radius: 6px;
    padding: 0 10px 0 0;
    width: 100%;
}

.textArea:focus {
    outline: none;
    background: #21212113;
    transition: 1s;
}

.textArea:focus {
    outline: none;
    background: none;
}

.container {
    margin: 0 auto;
    padding: 20px;
    max-width: 1500px;
}

.hoverSelect:hover {
    border: none !important;
}

.fileUpload {
    background: #212121e9 !important;
    color: white !important;
}

.fileUpload:hover {
    transition: 800ms;
    background: #212121ee !important;
    color: white!important;
}

.imgCourse {
    position: relative;
    top: 100px;
    filter: grayscale(100%);
}

@media screen and (max-width:800px) {}