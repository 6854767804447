.forgotPassword {
    background-image: url(../../assests/Images/imgHome/strip.png);
    height: 100vh;
    background-size: cover;
    background-position: center center;
    display: flex;
    justify-content: end;
    background-repeat: no-repeat;
}

.inputWidthPostion {
    width: 30%;
    margin-right: 10%;
    margin-top: 20%;
}

.myShadow {
    box-shadow: inset 0px 2px 2px 2px #0000001a;
    border: none;
}

.formCtrl {
    display: block;
    height: 40px;
    border: #0000001a 1px;
    border-style: none dashed dashed dashed;
    border-radius: 6px;
    padding: 0 10px 0 0;
    width: 100%;
}

.formCtrl:focus {
    outline: none;
    background: #21212113;
    transition: 1s;
}

.formCtrlPassoword {
    background: white;
    padding-left: 10px;
}

@media screen and (max-width:1300px) {
    .inputWidthPostion {
        width: 50%;
        margin-right: 10%;
        margin-top: 20%;
    }
    .forgotPassword {
        background-image: url(../../assests/Images/imgHome/stripMobile.png);
    }
}

@media screen and (max-width:1180px) {}

@media screen and (max-width:599px) {
    .inputWidthPostion {
        width: 75%;
        margin-right: 10%;
        margin-top: 20%;
    }
}