p {
    font-family: 'Open Sans', sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Open Sans', sans-serif !important;
    font-weight: 700;
}