.react-tabs {
    -webkit-tap-highlight-color: transparent;
}


/* line of tabs */

.react-tabs__tab-list {
    border-bottom: 1px solid #aaa;
    margin: 0 0 10px;
    padding: 0;
}


/* tab */

.react-tabs__tab {
    font-size: 1.2em;
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    /* under tab border px */
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 6px 12px;
    cursor: pointer;
    margin: 0 30px 0 0;
}

.react-tabs__tab--selected {
    background: #fff;
    border-color: #aaa;
    color: black;
    border-radius: 5px 5px 0 0;
    padding: 8px;
}

.react-tabs__tab--disabled {
    color: GrayText;
    cursor: default;
}

.react-tabs__tab:focus {
    outline: none;
}

.react-tabs__tab:focus:after {
    content: '';
    position: absolute;
    height: 5px;
    left: -4px;
    right: -4px;
    bottom: -5px;
    background: #fff;
}

.react-tabs__tab-panel {
    display: none;
}

.react-tabs__tab-panel--selected {
    display: block;
}

@media screen and (max-width:900px) {
    .react-tabs {
        -webkit-tap-highlight-color: transparent;
    }
    /* line of tabs */
    .react-tabs__tab-list {
        border-bottom: 1px solid #aaa;
        margin: 3px 0 !important;
        padding: 0;
    }
    /* tab */
    .react-tabs__tab {
        font-size: 0.9em;
        display: inline-block;
        border: 1px solid transparent;
        border-bottom: none;
        /* under tab border px */
        bottom: -1px;
        position: relative;
        list-style: none;
        padding: 6px 12px;
        cursor: pointer;
        margin: 0 30px 0 0;
    }
    .react-tabs__tab--selected {
        background: #fff;
        border-color: #aaa;
        color: black;
        border-radius: 5px 5px 0 0;
        padding: 8px;
    }
    .react-tabs__tab--disabled {
        color: GrayText;
        cursor: default;
    }
    .react-tabs__tab:focus {
        outline: none;
    }
    .react-tabs__tab:focus:after {
        content: '';
        position: absolute;
        height: 5px;
        left: -4px;
        right: -4px;
        bottom: -5px;
        background: #fff;
    }
    .react-tabs__tab-panel {
        display: none;
    }
    .react-tabs__tab-panel--selected {
        display: block;
    }
}

@media screen and (max-width:599px) {
    .react-tabs {
        -webkit-tap-highlight-color: transparent;
    }
    /* line of tabs */
    .react-tabs__tab-list {
        border-bottom: 1px solid #aaa;
        margin: 0 0 10px;
        padding: 0;
    }
    /* tab */
    .react-tabs__tab {
        font-size: 0.9em;
        display: inline-block;
        border: 1px solid transparent;
        border-bottom: none;
        /* under tab border px */
        bottom: -1px;
        position: relative;
        list-style: none;
        padding: 6px 8px;
        cursor: pointer;
        margin: 0 10px 0 0;
    }
    .react-tabs__tab--selected {
        background: #fff;
        border-color: #aaa;
        color: black;
        border-radius: 5px 5px 0 0;
        padding: 8px;
    }
    .react-tabs__tab--disabled {
        color: GrayText;
        cursor: default;
    }
    .react-tabs__tab:focus {
        outline: none;
    }
    .react-tabs__tab:focus:after {
        content: '';
        position: absolute;
        height: 5px;
        left: -4px;
        right: -4px;
        bottom: -5px;
        background: #fff;
    }
    .react-tabs__tab-panel {
        display: none;
    }
    .react-tabs__tab-panel--selected {
        display: block;
    }
}