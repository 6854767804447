.container {
    margin: 0 auto;
    max-width: 900px;
}

.imgCourse {
    height: 150px;
    background-position: center;
    background-size: cover;
}

.course {
    padding: 20px 0;
    /* border-top: 3px solid rgb(224, 224, 226); */
    border-bottom: 3px solid rgb(224, 224, 226);
}


/*
#  $$\      $$\                 $$\ $$\                  $$$$$$\                                $$\
#  $$$\    $$$ |                $$ |\__|                $$  __$$\                               \__|
#  $$$$\  $$$$ | $$$$$$\   $$$$$$$ |$$\  $$$$$$\        $$ /  $$ |$$\   $$\  $$$$$$\   $$$$$$\  $$\  $$$$$$\   $$$$$$$\
#  $$\$$\$$ $$ |$$  __$$\ $$  __$$ |$$ | \____$$\       $$ |  $$ |$$ |  $$ |$$  __$$\ $$  __$$\ $$ |$$  __$$\ $$  _____|
#  $$ \$$$  $$ |$$$$$$$$ |$$ /  $$ |$$ | $$$$$$$ |      $$ |  $$ |$$ |  $$ |$$$$$$$$ |$$ |  \__|$$ |$$$$$$$$ |\$$$$$$\
#  $$ |\$  /$$ |$$   ____|$$ |  $$ |$$ |$$  __$$ |      $$ $$\$$ |$$ |  $$ |$$   ____|$$ |      $$ |$$   ____| \____$$\
#  $$ | \_/ $$ |\$$$$$$$\ \$$$$$$$ |$$ |\$$$$$$$ |      \$$$$$$ / \$$$$$$  |\$$$$$$$\ $$ |      $$ |\$$$$$$$\ $$$$$$$  |
#  \__|     \__| \_______| \_______|\__| \_______|       \___$$$\  \______/  \_______|\__|      \__| \_______|\_______/
#                                                            \___|
*/

@media screen and (max-width:768px) {
    .course {
        text-align: center;
        width: 70%;
        margin: 0 auto;
    }
    .imgCourse {
        height: 200px;
        background-position: center;
        background-size: cover;
    }
}

@media screen and (max-width:992px) {
    .course {
        width: 85%;
        margin: 0 auto;
    }
}