.cartOpen {
    width: 24%;
    position: fixed;
    background-color: #eceff1;
    top: 74px;
    left: 0;
    z-index: 10;
    animation-name: cartShow;
    animation-duration: 500ms;
    border-radius: 0 0 14px 0;
    direction: rtl;
    height: 400px;
    box-shadow: inset 0px 2px 15px 10px #0000001a;
}

.cartClose {
    width: 24%;
    position: fixed;
    background-color: rgb(235, 235, 235);
    z-index: 10;
    top: 74px;
    animation-name: cartHide;
    animation-duration: 700ms;
    border-radius: 0 0 14px 0;
    direction: rtl;
    left: -50%;
    height: 400px;
    box-shadow: inset 0px 2px 15px 10px #0000001a;
}

.buyBtn {
    position: absolute;
    left: 43%;
    top: 6%;
}

@keyframes cartShow {
    0% {
        left: -50%;
    }
    100% {
        left: 0;
    }
}

@keyframes cartHide {
    0% {
        left: 0%;
    }
    100% {
        left: -50%;
    }
}

.imagProduct {
    width: 100px;
    height: 100px;
    background-size: cover;
    background-position: center;
}

.product {
    padding: 10px 2px 10px 10px;
    border-bottom: 1px solid black;
    cursor: pointer;
}

.scroll {
    padding: 0 20px;
    height: 280px;
    overflow-y: auto;
}

.scroll::-webkit-scrollbar {
    background-color: rgb(235, 235, 235);
    width: 10px;
}

.scroll::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.769);
    border-radius: 24px;
}

.cartIcon {
    position: relative;
    font-size: 90px;
    text-align: center;
}


/*
#  $$\      $$\                 $$\ $$\                  $$$$$$\                                $$\
#  $$$\    $$$ |                $$ |\__|                $$  __$$\                               \__|
#  $$$$\  $$$$ | $$$$$$\   $$$$$$$ |$$\  $$$$$$\        $$ /  $$ |$$\   $$\  $$$$$$\   $$$$$$\  $$\  $$$$$$\   $$$$$$$\
#  $$\$$\$$ $$ |$$  __$$\ $$  __$$ |$$ | \____$$\       $$ |  $$ |$$ |  $$ |$$  __$$\ $$  __$$\ $$ |$$  __$$\ $$  _____|
#  $$ \$$$  $$ |$$$$$$$$ |$$ /  $$ |$$ | $$$$$$$ |      $$ |  $$ |$$ |  $$ |$$$$$$$$ |$$ |  \__|$$ |$$$$$$$$ |\$$$$$$\
#  $$ |\$  /$$ |$$   ____|$$ |  $$ |$$ |$$  __$$ |      $$ $$\$$ |$$ |  $$ |$$   ____|$$ |      $$ |$$   ____| \____$$\
#  $$ | \_/ $$ |\$$$$$$$\ \$$$$$$$ |$$ |\$$$$$$$ |      \$$$$$$ / \$$$$$$  |\$$$$$$$\ $$ |      $$ |\$$$$$$$\ $$$$$$$  |
#  \__|     \__| \_______| \_______|\__| \_______|       \___$$$\  \______/  \_______|\__|      \__| \_______|\_______/
#                                                            \___|
*/

@media screen and (max-width:1300px) {
    .cart {
        top: 200px;
    }
}

@media screen and (max-width:900px) {
    .cartOpen {
        min-width: 46% !important;
        position: fixed;
        background-color: rgb(235, 235, 235);
        top: 70px;
        left: 0;
        z-index: 10;
        animation-name: cartShow;
        animation-duration: 500ms;
        border-radius: 0 0 14px 0;
        direction: rtl;
    }
    .cartClose {
        min-width: 46%;
        position: fixed;
        background-color: rgb(235, 235, 235);
        z-index: 10;
        top: 70px;
        animation-name: cartHide;
        animation-duration: 700ms;
        border-radius: 0 0 14px 0;
        direction: rtl;
        left: -100%;
        /* display: none; */
    }
    @keyframes cartShow {
        0% {
            left: -100%;
        }
        100% {
            left: 0;
        }
    }
    @keyframes cartHide {
        0% {
            left: 0%;
        }
        100% {
            left: -100%;
        }
    }
}

@media screen and (max-width:599px) {
    .cartOpen {
        min-width: 100% !important;
        position: fixed;
        background-color: rgb(235, 235, 235);
        top: 62.5px;
        left: 0;
        z-index: 10;
        animation-name: cartShow;
        animation-duration: 500ms;
        border-radius: 0 0 8px 8px;
        direction: rtl;
    }
    .cartClose {
        min-width: 100%;
        position: fixed;
        background-color: rgb(235, 235, 235);
        z-index: 10;
        top: 62.5px;
        animation-name: cartHide;
        animation-duration: 700ms;
        border-radius: 0 0 8px 8px;
        direction: rtl;
        left: -100%;
        /* display: none; */
    }
    @keyframes cartShow {
        0% {
            left: -100%;
        }
        100% {
            left: 0;
        }
    }
    @keyframes cartHide {
        0% {
            left: 0%;
        }
        100% {
            left: -100%;
        }
    }
}